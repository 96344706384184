export { wrapRootElement } from './src/apollo/provider';
// trigger an immediate page refresh when an update is found
export const onServiceWorkerUpdateReady = () => window.location.reload(true);
// export const onServiceWorkerUpdateFound = () => {
//     if (
//         window.confirm(
//             "This site has been updated with new data. Do you wish to reload the site to get the new data?"
//         )
//     ) {
//         window.location.reload(true);
//     }
// };
