module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"magecom-website-development","short_name":"Magecom","start_url":"/","background_color":"#ed5d2b","theme_color":"#ed5d2b","display":"fullscreen","cache_busting_mode":"none","icon":"/var/www/magecom.net/releases/20241106183251/src/assets/img/favicon.png","orientation":"portrait","shortcuts":[{"name":"Contact page","short_name":"Contact us","description":"Magento website development and design agency","url":"/contacts/","icons":[{"src":"/var/www/magecom.net/releases/20241106183251/src/assets/img/icon-192x192.png","sizes":"192x192"}]}],"categories":["business"],"legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/blog/*","/case-studies/","/events-calendar/*","/portfolio/","/what-we-do/","/careers/","/vacancies/*","/contacts/","/privacy-policy/"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
